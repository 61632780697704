$form-width: 528px

.auth-grid
  &__content
    display: flex
    flex-direction: column
    min-height: 100vh
    margin-right: $form-width
    padding-top: 32px
    color: #fff
    background-color: $brand-primary-xdark

  &__body
    flex: auto

  &__contacts
    position: sticky
    bottom: 0
    margin-top: 56px
    padding: 16px 0
    background-color: #25314F

  &__form
    position: fixed
    top: 0
    right: 0
    bottom: 0
    width: $form-width
    overflow-y: auto
    background-color: #fff


.auth-contacts
  display: flex
  justify-content: space-between
  align-items: center

  &__links
    display: flex

  &__link
    text-decoration: none
    color: $border-color

  &__btn
    display: flex
    align-items: center
    height: 40px
    padding: 0 24px
    border: 2px solid $brand-gray-dark
      radius: 8px
    color: $border-color


.auth-panel
  padding: 16px 20px
  border-radius: 12px
  line-height: $line-height-text
  background-color: rgba(#fff, 0.07)

  ol
    padding-inline-start: 0

  li + li
    margin-top: 12px

  ul
    margin-top: 12px
    list-style-type: none

    li::before
      content: '\2014'
      position: absolute
      margin-left: -20px

  a
    color: inherit

  &__btn
    font-weight: $font-weight-bold
    color: $brand-primary-light


.auth-container
  display: flex
  flex-direction: column
  justify-content: space-between
  min-height: 100%
  padding: 24px 60px 24px

  &__body
    flex: auto

  &__footer
    display: flex
    justify-content: space-between
    flex: none
    margin:
      right: -32px
      left: -32px
    padding-top: 48px


.auth-strength
  display: flex
  margin-left: -4px

  &__item
    flex: auto
    height: 5px
    margin-left: 4px
    background-color: $brand-gray-other

    &--danger
      background-color: #FF6B6B

    &--warning
      background-color: #FFAB5A

    &--other
      background-color: #D1E458

    &--success
      background-color: #80E17E


.auth-validation-list
  margin: 0
    left: 1em
  padding: 0

  &__item
    line-height: $line-height-text
    color: $brand-danger

    &:not(:first-child)
      margin-top: 4px

    &--checked
      color: $brand-success


.auth-help
  line-height: $line-height-text
  color: $brand-gray-dark


.auth-files-dropzone
  position: relative
  display: flex
  flex-direction: column
  align-items: center
  padding: 24px
  border: 2px dashed $border-color
    radius: 8px
  color: $brand-gray-dark

  &__title
    font-weight: $font-weight-bold

.auth-not-certificate
  color: $brand-gray-dark

.auth-file
  display: flex
  align-items: center
  padding: 8px
  border: 1px solid $brand-gray-other
    radius: 8px
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1)

  &__object
    display: flex
    align-items: center
    justify-content: center
    flex: none
    width: 42px
    height: 42px
    border-radius: 8px
    background-color: $brand-gray-xlight

  &__body
    display: flex
    align-items: center
    justify-content: space-between
    flex: auto
    padding-left: 12px
    line-height: $line-height-text

  &__size
    color: $brand-gray-dark

  &__btn-close
    display: flex
    align-items: center
    justify-content: center
    width: 40px
    height: 40px


.modal-description
  line-height: $line-height-text

  a
    font-weight: $font-weight-bold
    text-decoration: none
    color: $brand-primary


