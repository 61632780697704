.login-layout
  display: flex
  flex-direction: column
  min-height: 100vh
  background-color: #ffffff

  &__header
    flex: none
    padding: 16px 24px

  &__body
    flex: auto
    display: flex
    align-items: center
    justify-content: center

  &__footer
    flex: none
    display: flex
    align-items: center
    height: 72px
    padding: 0 32px
    background-color: $brand-gray-xlight

    a, button span:not(:hover)
      text-decoration: none
      color: $brand-primary-xdark


.login-layout-footer-nav
  display: flex
  gap: 24px


.login-layout-body
  width: 412px


.login-layout-monitor
  display: inline-block
  width: 142px
  vertical-align: top
