.badge
  color: white
  width: 23px
  height: 20px
  border-radius: 50%
  font-size: $font-size-xsmall
  line-height: $font-size-xsmall
  background-color: $brand-primary-light
  display: flex
  align-items: center
  justify-content: center