.learners-search-grid
  display: flex

  &__main
    flex: auto
    width: 50%
    padding-right: 10px

  &__options
    flex: none
    width: 50%
    padding-left: 10px

.learners-input-grid
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-gap: 8px

.learners-modal-grid
  display: flex
  margin: -20px 0

  &__col
    width: 50%

    &:first-child
      padding-right: 20px

    &:last-child
      padding-left: 20px
      border-left: 1px solid $brand-gray

.learners-form-group
  display: flex

  &__input
    flex: auto
    margin-right: -8px

  &__btn
    z-index: 1
    flex: none
    background-color: #fff

    span
      border:
        bottom-left-radius: 0
        top-left-radius: 0
      font-weight: $font-weight-bold

.learners-modal-ul
  +reset-ul
  margin: 0 -20px 20px

  &__item
    padding: 16px 20px
    border-top: 1px solid $brand-gray-other

    &:last-child
      border-bottom: 1px solid $brand-gray-other

  &__btn
    display: flex
    align-items: center
    justify-content: center
    width: 20px
    height: 20px
    margin-left: auto

    .fill
      fill: $brand-gray

.learners-modal-right
  display: flex
  flex-direction: column
  height: 100%

  &__head
    flex: none
    padding: 16px 20px
    border-bottom: 1px solid $brand-gray-other

    &-close
      margin-left: auto

  &__body
    flex: auto
    overflow-y: auto
    +brand-scrollbar

    .loader
      display: flex
      justify-content: center

    &-inner
      padding: 12px 20px

  &__controls
    display: flex
    flex: none
    justify-content: flex-end
    padding: 16px 20px
    border-top: 1px solid $brand-gray-other

  &__loader
    height: 100%
    display: flex
    justify-content: center
    align-items: center

.learner-history-select-types
  width: 456px

.learner-history-select-status
  width: 232px

.learner-history-list
  +reset-ul

  &__item
    padding: 20px 0
    border-top: 1px solid $border-color

    &--show-history
      padding-bottom: 0

  .learner-history-list
    .learner-history-list__item
      padding: 0

      &:first-child
        .learner-history-media:before
          top: 50%

      &:last-child
        .learner-history-media__arrow
          display: none

.learner-history-media
  position: relative
  display: flex
  align-items: center

  &:before
    content: ''
    position: absolute
    top: 0
    bottom: 0
    left: 100px
    width: 4px
    background-color: $brand-primary-xlight

  &__object
    width: 104px
    padding: 20px 0
    flex: none

  &__body
    flex: auto
    padding: 20px 0 20px 40px

  &__icon
    position: absolute
    top: 50%
    left: 102px
    display: flex
    align-items: center
    justify-content: center
    width: 32px
    height: 32px
    border-radius: 50%
    transform: translate(-50%, -50%)

    &--warning
      background-color: $brand-warning

    &--primary
      background-color: $brand-primary-light

    &--success
      background-color: $brand-success

  &__arrow
    position: absolute
    bottom: -3px
    left: 102px
    transform: translateX(-50%)

.learner-history-media-body
  display: flex

  &__item
    width: 50%

  &__detail
    display: flex
    padding: 10px 0
    border-top: 1px solid $brand-gray-other

    &:first-child
      padding-top: 0
      border-top: none

    &:last-child
      padding-bottom: 0

    &-label
      width: 140px
      color: $brand-gray-dark
      flex: none

    &-value
      overflow-wrap: anywhere

.learner-no-data
  padding: 0 24px 24px 24px
  color: $brand-gray-dark

.learners-table-name
  color: $brand-primary
  font-weight: $font-weight-bold
  text-decoration: none

.learner-document-header
  align-items: center

  .learner-document-toggle-table
    display: flex
    align-items: center

    &-label
      font-size: 14px
      font-weight: normal

.table-data__required
  font-size: $font-size-medium
  color: $brand-danger

.table-bad-visible-field
  color: $brand-gray-dark
