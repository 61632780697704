
.table-classic
    border: 1px solid black
    border-collapse: collapse
    width: 100%

    td, th
        border: 1px solid black
        padding: 7px
        text-align: left

    &__header
        font-weight: bold
