// Emphasis & misc
.font-weight-base
  font-weight: $font-weight-base

.font-weight-bold
  font-weight: $font-weight-bold

.line-height-base
  line-height: $line-height-base

.line-height-text
  line-height: $line-height-text

.font-size-medium
  font-size: $font-size-medium

.font-size-base
  font-size: $font-size-base

.font-size-small
  font-size: $font-size-small

.font-size-xsmall
  font-size: $font-size-xsmall


// Alignment
.text-left
  text-align: left

.text-right
  text-align: right

.text-center
  text-align: center


// Contextual colors
.color-gray-dark
  color: $brand-gray-dark

  path
    fill: $brand-gray-dark

.color-gray
  color: $brand-gray

.color-gray-other
  color: $brand-gray-other

.color-primary
  color: $brand-primary

.color-primary-dark
  color: $brand-primary-dark

.color-danger
  color: $brand-danger

.color-danger-dark
  color: $brand-danger-dark

.color-success
  color: $brand-success

.color-success-dark
  color: $brand-success-dark

.color-text
  color: $text-color

.color-warning
  color: $brand-warning

.color-warning-dark
  color: $brand-warning-dark
