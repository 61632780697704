.org-input-time
  width: 115px

.org-schedule-panel
  margin:
    top: 20px
    right: -20px
    left: -20px
  border-top: 1px solid $brand-gray-other

  > div
    box-shadow: none

.org-service-panel
  padding-bottom: 1000px

.org-signed-details
  margin-left: 26px

  &__item
    display: flex
    margin-top: 12px

  &__label
    width: 108px
    color: $brand-gray-dark

.org-photo
  display: flex
  min-height: calc(100vh - 386px)

  &__object
    display: flex
    flex: auto
    margin-right: 12px
    border-radius: 8px
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1)

  &__list
    flex: none
    width: 436px
    //max-height: calc(100vh - 246px)
    border-radius: 8px
    overflow-y: auto
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1)
    background-color: #fff

.org-photo-ul
  +reset-ul

  &__item:not(:last-child)
    border-bottom: 1px solid $brand-gray-other

.org-photo-media
  position: relative
  display: flex
  align-items: center
  padding: 4px
  border-left: 4px solid transparent

  &--active
    border-left-color: $brand-primary-light
    background-color: $brand-primary-xlight

  &__object
    width: 78px
    height: 52px
    border-radius: 4px
    object-fit: contain
    object-position: center
    font-family: 'object-fit: contain; object-postion: center;'

  &__body
    padding-left: 12px
    width: 70%
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

  &__btn-select
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    width: 100%

  &__btn-delete
    position: absolute
    z-index: 1
    top: 50%
    right: 20px
    display: flex
    align-items: center
    justify-content: center
    width: 20px
    height: 20px
    transform: translateY(-50%)

.org-photo-dropzone
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  width: 100%
  border-radius: 8px
  color: $brand-gray-dark
  background-color: #fff

  &__title
    font-weight: $font-weight-bold

.org-crop-photo
  display: flex
  flex-direction: column
  width: 100%

  &__object
    display: flex
    align-items: center
    justify-content: center
    flex: auto
    border-top-left-radius: 8px
    border-top-right-radius: 8px
    background-color: $border-color

  &__name
    flex: none
    padding: 20px
    border-bottom-left-radius: 8px
    border-bottom-right-radius: 8px
    background-color: #fff

.organization-select-group
  max-width: 382px

.organization-toggle-group
  display: flex
  flex-wrap: wrap
  margin:
    top: -16px
    left: -20px

  &__item
    flex: none
    padding:
      top: 16px
      left: 20px

.organization-file
  display: flex
  justify-content: center
  cursor: pointer

.organization-table-no-data
  color: $brand-gray-dark

.organization-table-name
  color: $brand-primary
  font-weight: $font-weight-bold
  text-decoration: none
