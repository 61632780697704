.desktop-container
  display: grid
  grid-template-columns: 60fr 40fr
  grid-template-rows: 380px
  gap: 20px

.desktop-links-grid
  display: flex
  flex-direction: column
  grid-gap: 20px

.desktop-media
  position: relative
  display: flex
  align-items: center
  padding: 22px 32px

  &__body
    padding-right: 8px
    font-size: $font-size-base
    line-height: $line-height-text

  &__object
    flex: none
    margin-left: auto

  &__link
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0

.desktop-notifications
  &__title
    font-size: $font-size-medium
    font-weight: $font-weight-bold
    padding: 8px 0

  &__no-data
    padding: 0 24px 24px 0
    color: $brand-gray-dark

.desktop-events
  display: flex

  > div
    display: flex
    flex-direction: column
    width: 100%
    overflow: hidden

  &__title
    margin-left: -8px
    font-size: $font-size-medium

  &__controls
    margin-right: -8px

  &__container
    flex: auto
    padding: 8px 0
    border-top: 1px solid $brand-gray-other
    overflow-y: auto
    +brand-scrollbar

  &__no-data
    padding: 0 24px 24px 16px
    color: $brand-gray-dark

.desktop-notification
  padding: 12px 16px
  border-radius: 8px
  background-color: $brand-gray-other

  &__head
    display: flex
    justify-content: space-between

  &__title
    display: flex
    align-items: center

  &__body
    margin-top: 8px
    line-height: $line-height-text
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: center
    width: 100%

    a
      text-decoration: none
      color: $brand-primary
      font-weight: $font-weight-bold

      &:visited
        color: $brand-primary

    span
      font-size: $font-size-small!important

  &__text
    width: 100%

.desktop-info
  padding: 16px 0

  &__head
    display: flex
    align-items: center
    justify-content: space-between

  &__title
    font-size: $font-size-base
    font-weight: $font-weight-bold

  &__body
    margin-top: 16px

    h4
      font-size: $font-size-base

    p
      font-size: $font-size-small!important
