.error-layout
  display: flex
  flex-direction: column
  min-height: 100vh


.error-container
  flex: auto
  line-height: $line-height-text
  font-size: $font-size-base
  text-align: center

  a
    font-weight: $font-weight-bold
    text-decoration: none
    color: $brand-primary


.error-heading
  font:
    size: $font-size-xlarge
    weight: $font-weight-black
  color: $brand-primary-xdark


.error-lead
  font:
    size: $font-size-medium
    weight: $font-weight-bold
  color: $brand-primary-xdark


.error-img
  display: inline-block
  max-width: 100%
  vertical-align: top
