.infobox
  padding: 0 $grid-gap
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1)

  &--border-radius
    border-radius: 8px
    box-shadow: none

  &--danger
    background-color: #FFF1F1

  &--success
    background-color: #F3FDF3

  &--primary
    background-color: #EFF6FF

  &--warning
    background-color: #FEF6EE

  &--in-page
    border-radius: 8px
    box-shadow: none

  &__head
    display: flex
    align-items: center
    min-height: 60px
    padding: 12px 0

  &__body
    flex: auto

  &__control
    display: flex
    flex: none
    margin-left: 12px

  &__details
    margin-top: -12px
    padding-bottom: 20px

    a, span
      font-weight: $font-weight-bold
      text-decoration: none
      color: $brand-primary
      cursor: pointer