// TODO: remove service.sass when merge to 15.9. this file is prioritized
.service-panels-list
  &__item
    &:not(:first-child)
      margin-top: 8px


.service-child-media
  display: flex

  &__num
    flex: none
    padding-top: 2px

  &__body
    flex: auto
    padding: 0 16px

  &__status
    flex: none


.ul-small
  +reset-ul

  &__item
    &:not(:first-child)
      margin-top: 8px
