@mixin scrollbar($size, $foreground-color, $background-color: transparent)
  &::-webkit-scrollbar
    width:  $size
    height: $size

  &::-webkit-scrollbar-thumb
    border-radius: 8px
    background: $foreground-color

  &::-webkit-scrollbar-track
    background: $background-color

  scrollbar-color: $foreground-color $background-color


@mixin brand-scrollbar
  +scrollbar(8px, rgba(#B0B3C3, 0.6))

  &:hover
    +scrollbar(8px, #B0B3C3)


@mixin brand-scrollbar-invert
  +scrollbar(8px, rgba(#8B99BB, 0.6))

  &:hover
    +scrollbar(8px, #8B99BB)