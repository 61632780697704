.modal-right
  position: fixed
  top: 0
  right: 0
  bottom: 0
  max-width: 498px
  min-width: 498px
  background-color: #fff


