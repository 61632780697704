// Position
.relative
  position: relative

// Display
.block
  display: block

.inline-block
  display: inline-block

.flex
  display: flex

.flex-column
  flex-direction: column

.flex-auto
  flex: auto

.flex-none
  flex: none

.items-start
  align-items: flex-start !important

.items-center
  align-items: center !important

.items-end
  align-items: flex-end

.items-baseline
  align-items: baseline

.justify-end
  justify-content: flex-end

.justify-between
  justify-content: space-between

.justify-center
  justify-content: center

.wrap
  flex-wrap: wrap

.flex-direction-row
  flex-direction: row

.ml-auto
  margin-left: auto


// cursor
.cursor-pointer
  cursor: pointer

// Link
.brand-link
  +link-brand

.word-break-all
  word-break: break-all

.overflow-wrap-anywhere
  overflow-wrap: anywhere

.white-space-nowrap
  white-space: nowrap

.text-decoration-underline
  &:hover
    text-decoration: underline
