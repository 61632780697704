.loading__modal
  text-align: center
  line-height: 20px

  a
    color: $brand-primary-light
    font-weight: bold
    text-decoration: none

  .loading__modal--progress
    width: 500px

    .linear
      display: block
      height: 16px
      position: relative
      background-color: $brand-gray-xlight

      .linear__progress
        width: 100%
        position: absolute
        left: 0
        height: 100%
        display: inline-block
        background-color: $brand-primary-light
        animation-name: progress
        animation-duration: 360s
        animation-timing-function: linear
        transition: all linear


@keyframes progress
  0%
    width: 0
  25%
    width: 20%
  50%
    width: 50%
  100%
    width: 100%