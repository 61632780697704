.room-panel
  padding: 20px 0
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1)
  background-color: #fff


.room-save-container
  position: sticky
  right: 0
  bottom: 0
  left: 0
  z-index: 14 // установлено в зависимости от react select выпадашки
  margin:
    top: auto
    bottom: -24px

  &--portal
    margin-bottom: 0


.room-panel-save
  margin-top: 24px
  padding: 16px 0
  box-shadow: 0 -1px 8px rgba(146, 146, 146, 0.2)
  background-color: #fff

  &__inner
    display: flex
    justify-content: flex-end


.room-form-group
  &__btn
    display: block
    width: 100%
    margin-bottom: -20px
    font-weight: $font-weight-bold
    line-height: 20px
    text-align: right
    color: $brand-primary


.room-notify-table
  width: 100%
  margin-bottom: -20px
  border-collapse: collapse
  text-align: left

  tr
    position: relative

    &:nth-child(even)
      background-color: #f9f9fb

    &:not(:first-child)
      border-top: 1px solid $brand-gray-other

  td
    padding:
      top: 16px
      bottom: 16px

    &:first-child
      width: 100%
      padding-left: $grid-gap

    &:last-child
      padding-right: $grid-gap

  &__checkbox
    padding-left: 8px

    div
      width: 34px

  &__label
    &::before
      content: ''
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0
