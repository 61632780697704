.disciplines-table-comment
  display: flex
  align-items: center

  &.right
    justify-content: flex-end

    .disciplines-table-comment__controls
      margin:
        right: 0

  &__body
    flex: auto

  &__controls
    display: flex
    flex: none
    margin:
      right: -12px
      left: 20px