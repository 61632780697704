.doc-list
  +reset-ul

  &__item
    position: relative
    padding:
      top: 16px
      bottom: 16px
    background-color: #fff

    &:not(:first-child)
      margin-top: 8px


.doc-media
  display: flex
  align-items: flex-start

  &__body
    flex: auto
    padding:
      right: 8px
      left: 8px

  &__img
    flex: none
    display: block
    width: 20px
    height: 20px

  &__link
    text-decoration: none
    color: $text-color

    &::before
      content: ''
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0