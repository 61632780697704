.teachers-input-grid
  display: grid
  grid-template-columns: 1fr 1fr auto
  grid-gap: 20px


.teacher-history-input-grid
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-gap: 20px


.teachers-link
  display: flex
  align-items: center
  justify-content: center
  flex: none
  width: 24px + 16px
  height: 24px
  margin-left: auto
  padding-left: 16px


.teacher-panel
  &__heading
    display: flex
    justify-content: space-between
    align-items: center
    margin:
      top: -4px
      bottom: 16px

  &__title
    display: flex
    font:
      size: $font-size-base
      weight: $font-weight-bold

    span
      padding-left: 8px
      color: $brand-gray-dark

  &__select
    min-width: 196px

  &__controls
    display: flex
    align-items: center
    justify-content: space-between
    padding-top: 20px

    .fill
      fill: $text-color

    &_select
      width: 100%
      margin-right: 8px

  &__subtitle
    color: $brand-gray-dark


.teacher-table-comment
  display: flex
  align-items: center
  justify-content: flex-end

  &__body
    flex: auto

  &__controls
    display: flex
    flex: none
    margin:
      left: 20px

.teacher-no-data
  padding: 0 24px 24px 24px
  color: $brand-gray-dark


.teachers-table-link

  &__item
    white-space: nowrap
  