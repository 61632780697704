.sider-scroll
  flex: auto
  overflow-y: scroll
  +brand-scrollbar-invert

  &__container
    width: $sider-width-sm - 12px
    padding: 4px 0 0 12px
    transition: padding $transition-delay

    @at-root .sider-show &
      width: $sider-width - 12px
      margin-bottom: 12px


.sider-header
  position: relative
  display: flex
  align-items: center
  flex: none
  height: 68px
  padding: 0 24px
  background-color: #25314F

  &__logo
    height: 40px
    opacity: 0
    visibility: hidden
    transition: $transition-delay

    @at-root .sider-show &
      opacity: 1
      visibility: visible

  &__btn
    position: absolute
    top: 50%
    right: 50%
    transform: translate(50%, -50%) scale(-1, 1)
    transition: $transition-delay

    @at-root .sider-show &
      right: 24px
      left: auto
      transform: translateY(-50%)

    img
      width: 24px
      height: 24px


.sider-divider
  margin: 0 12px 12px
  height: 2px
  transition: $transition-delay
  opacity: 1
  visibility: visible
  background-color: $brand-gray-dark

  @at-root .sider-show &
    height: 0
    margin: 0
    opacity: 0
    visibility: hidden


.sider-title
  padding: 20px 0 0
  line-height: $line-height-text
  font:
    size: 0
    weight: $font-weight-bold
  letter-spacing: 0.12em
  text-transform: uppercase
  white-space: nowrap
  opacity: 0
  visibility: hidden
  transition: $transition-delay
  color: $brand-gray

  @at-root .sider-show &
    padding:
      top: 12px
      bottom: 4px
      left: 12px
    font-size: 11px
    opacity: 1
    visibility: visible


.sider-ul
  &__li
    display: flex
    align-items: center
    width: 42px
    padding: 9px 12px
    border-radius: 8px
    font-weight: $font-weight-bold
    text-decoration: none
    transition: $transition-delay
    color: $border-color

    &:not(:first-child)
      margin-top: 4px

    &:hover, &.active
      color: #fff
      background-color: $brand-primary-light

      path
        fill: #fff

    @at-root .sider-show &
      width: 266px
      height: auto
      padding: 10px 12px

    path
      fill: $border-color

  &__icon
    flex: none
    width: 18px
    height: 18px

    svg
      width: inherit
      height: inherit

  &__text
    padding-left: 0
    line-height: 20px
    white-space: nowrap
    font-size: 0
    opacity: 0
    overflow: hidden
    visibility: hidden
    transition: $transition-delay

    @at-root .sider-show &
      padding-left: 12px
      line-height: 18px
      font-size: inherit
      text-align: left
      white-space: normal
      opacity: 1
      visibility: visible


.sider-footer
  flex: none
  padding-top: 24px

  @at-root .sider-show &
    padding-top: 24px


.sider-help
  position: relative
  height: 36px
  margin: 0 12px 12px
  padding: 12px 16px
  border-radius: 8px
  transition: height $transition-delay
  background-color: rgba(#fff, 0.1)

  @at-root .sider-show &
    height: 128px

  &__title
    line-height: $line-height-text
    font:
      size: 0
      weight: $font-weight-bold
    opacity: 0
    transition: $transition-delay
    color: #fff

    @at-root .sider-show &
      opacity: 1
      font-size: $font-size-base

  &__btn
    display: inline-flex
    align-items: center
    height: 0
    margin-top: 8px
    padding: 0
    border-radius: 8px
    vertical-align: middle
    text-decoration: none
    font-size: 0
    transition: $transition-delay
    color: #fff
    background-color: $brand-primary-light

    @at-root .sider-show &
      height: 36px
      padding: 0 20px
      font-size: inherit

    &::before
      content: ''
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0

      @at-root .sider-show &
        display: none

  &__my-link
    display: flex
    align-items: center
    margin-top: 16px
    text-decoration: none
    font-size: 0
    opacity: 0
    transition: $transition-delay
    color: #fff

    @at-root .sider-show &
      font-size: inherit
      opacity: 1

    &-counter
      display: flex
      align-items: center
      height: 0
      margin-left: 8px
      padding: 0
      border: 0 solid #fff
        radius: 10px
      font-size: 0
      transition: $transition-delay

      @at-root .sider-show &
        height: 20px
        padding: 0 8px
        border-width: 1px
        font-size: $font-size-xsmall

  &__img
    position: absolute
    right: 0
    bottom: -50%
    width: 76px
    opacity: 0
    transform: scale(0)
    transition: $transition-delay

    @at-root .sider-show &
      right: 12px
      bottom: 0
      opacity: 1
      transform: scale(1)

  &__icon
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    display: flex
    align-items: center
    justify-content: center
    opacity: 1
    transform: scale(1)
    transition: $transition-delay

    @at-root .sider-show &
      opacity: 0
      transform: scale(0)

    svg
      width: 18px
      height: 18px
