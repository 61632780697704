$global-header-height: 40px

.global-header
  position: fixed
  z-index: 1000
  width: 100%

  &__nav
    margin-left: 28px

  &__details
    display: flex
    align-items: center
    margin-left: auto

  ~ .layout
    &, & .layout__sider
      padding-top: $global-header-height

    &, & .layout__content
      min-height: calc(100vh - #{$global-header-height})

    .page-heading
      top: $global-header-height

    .sider-header
      height: 40px

      &__logo
        display: none


.global-header-nav
  display: flex
  gap: 28px

  &__item
    display: flex
    align-items: center
    text-decoration: none
    font-weight: $font-weight-bold
    opacity: 0.8
    color: #ffffff

    &.active
      opacity: 1
      cursor: default
      pointer-events: none

    svg
      width: 18px
      height: 18px
      margin-right: 6px


.global-header-user
  display: flex
  align-items: center
  margin-left: 40px

  &__avatar
    display: flex
    align-items: center
    justify-content: center
    width: 24px
    height: 24px
    border-radius: 50%
    font-size: 10px
    font-weight: $font-weight-black
    letter-spacing: 0.1em
    color: #ffffff
    background-color: #FD7E14

  &__name
    margin-left: 8px
    font-weight: $font-weight-bold
    color: #ffffff

  &__role
    display: flex
    align-items: center
    height: 24px
    margin-left: 8px
    padding: 0 8px
    border-radius: 8px
    font-size: 10px
    font-weight: $font-weight-black
    letter-spacing: 0.08em
    text-transform: uppercase
    color: #ffffff
    background-color: #4C6EF5

  &__arrow
    margin-left: 8px


.global-header-options
  display: flex
  align-items: center
  gap: 20px

  &__item
    position: relative
    display: flex
    align-items: center
    justify-content: center
    width: 24px
    height: 24px

  &__counter
    position: absolute
    top: -5px
    right: -5px
    display: flex
    align-items: center
    height: 14px
    padding: 0 4px
    border: 2px solid #364FC7
    border-radius: 9px
    box-sizing: content-box
    font-size: 11px
    font-weight: $font-weight-black
    color: #ffffff
    background-color: #FD7E14

