// Китовые переменные, наследованные из src/theme/global-styles.ts.
$brand-primary: var(--brand-primary)
$brand-primary-light: var(--brand-primary-light)
$brand-primary-xlight: var(--brand-primary-xlight)
$brand-primary-dark: var(--brand-primary-dark)
$brand-primary-xdark: var(--brand-primary-xdark)

$brand-success: var(--brand-success)
$brand-success-light: var(--brand-success-light)
$brand-success-xlight: var(--brand-success-xlight)
$brand-success-dark: var(--brand-success-dark)
$brand-success-xdark: var(--brand-success-xdark)

$brand-warning: var(--brand-warning)
$brand-warning-light: var(--brand-warning-light)
$brand-warning-xlight: var(--brand-warning-xlight)
$brand-warning-dark: var(--brand-warning-dark)
$brand-warning-xdark: var(--brand-warning-xdark)

$brand-danger: var(--brand-danger)
$brand-danger-light: var(--brand-danger-light)
$brand-danger-xlight: var(--brand-danger-xlight)
$brand-danger-dark: var(--brand-danger-dark)
$brand-danger-xdark: var(--brand-danger-xdark)

$brand-gray-dark: var(--brand-gray-dark)
$brand-gray: var(--brand-gray)
$brand-gray-light: var(--brand-gray-light)
$brand-gray-xlight: var(--brand-gray-xlight)
$brand-gray-other: var(--brand-gray-other)
$border-color: var(--border-color)

$text-color: var(--text-color)
$title-color: var(--title-color)

$font-family-base: var(--font-family-base)
$font-size-xlarge: var(--font-size-xlarge)
$font-size-large: var(--font-size-large)
$font-size-xmedium: var(--font-size-xmedium)
$font-size-medium: var(--font-size-medium)
$font-size-base: var(--font-size-base)
$font-size-small: var(--font-size-small)
$font-size-xsmall: var(--font-size-xsmall)
$font-weight-base: var(--font-weight-base)
$font-weight-bold: var(--font-weight-bold)
$font-weight-black: var(--font-weight-black)
$line-height-base: var(--line-height-base)
$line-height-text: var(--line-height-text)


// Глобальные переменные проекта
// Colors
$body-bg:                               #f1f0f6

// Media queries breakpoints
$screen-wide:                           1280px
$screen-desktop:                        1024px
$screen-tablet:                         768px
$screen-phone:                          480px

// Grid system
$grid-gap:                              var(--grid-gap)

// Objects
$sider-width:                           290px
$sider-width-sm:                        66px

$transition-delay: 300ms