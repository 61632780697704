.faq-panel
  background-color: #fff

  &__heading
    padding: 16px 0
    line-height: $line-height-text
    font-weight: $font-weight-bold
    text-transform: uppercase
    letter-spacing: 0.05em
    color: $brand-gray-dark

  &__item
    padding: 16px 0
    border-top: 1px solid $brand-gray-xlight


.faq-accordion
  &__btn
    display: flex
    align-items: flex-start
    justify-content: space-between
    width: 100%

  &__title
    font-weight: $font-weight-bold
    line-height: $line-height-text
    color: $title-color
    text-align: left

  &__description
    margin-top: 10px
    line-height: $line-height-text

    a
      text-decoration: none
      color: $brand-primary-light

