.requests-search-grid
  display: grid
  grid-template-columns: 300px 1fr
  grid-gap: 20px


.requests-search-grid2
  display: grid
  grid-template-columns: 1fr 1fr
  grid-gap: 20px


.requests-search-tabs
  padding:
    right: $grid-gap
    left: $grid-gap
  border-bottom: 1px solid $brand-gray-other


.requests-child-name
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-gap: 8px


.requests-search-overdue
  display: grid
  grid-template-columns: repeat(3, 132px)
  grid-gap: 8px


.requests-form-item
  padding-bottom: 16px

  &:not(:first-child)
    padding-top: 16px
    border-top: 1px solid $brand-gray-other


.requests-child-list
  +reset-ul

  &__item
    padding-top: 12px
    padding-bottom: 12px
    border-bottom: 1px solid $brand-gray-other


.requests-radio-label
  display: flex
  font-size: $font-size-small

  &__label
    flex: auto
    padding-right: 16px

  &__id
    flex: none
    padding-right: 16px
    color: $brand-gray-dark

  &__name
    width: 17%
    padding-right: 16px

  &__date
    width: 17%
    padding-right: 16px

  &__num
    width: 17%


.requests-radio-list
  &__item
    padding: 16px 24px
    border-top: 1px solid $brand-gray-other

    &:last-child
      border-bottom: 1px solid $brand-gray-other

    &:nth-child(even)
      background-color: #f9f9fb


.requests-infobox-table
  &__row
    display: flex

    &:not(:first-child)
      margin-top: 16px

  &__type
    width: 50%
    padding-left: 28px

  &__dates
    width: 30%

  &__result
    width: 20%

.requests-infobox-icon
  &--primary
    path
      fill: $brand-primary-dark

  &--danger
    path
      fill: $brand-danger-dark

  &--success
    path
      fill: $brand-success-dark

  &--warning
    path
      fill: $brand-warning-dark

.requests-search-group-grid
  display: grid
  grid-gap: 20px
  grid-template-columns: 10fr 7fr 1fr


.requests-hr
  padding-top: 16px
  border-top: 1px solid $brand-gray-other

.requests-no-data
  padding: 0 24px 24px 24px
  color: $brand-gray-dark


.requests-check-details
  margin: -20px

  &__item:not(:first-child)
    border-top: 1px solid $brand-gray-other

  &__user
    background-color: #F9F9FB

  &__inner
    padding: 16px 20px

  &__grid
    display: grid
    grid-gap: 24px
    grid-template-columns: 38fr 33fr 19fr
