.footer
  display: flex
  flex: none
  padding: 16px 24px
  border-top: 1px solid $border-color

  &__body
    flex: auto

  &__nav
    display: flex
    flex-wrap: wrap
    margin:
      top: -16px
      left: -20px

    &-item
      padding:
        top: 16px
        left: 20px

    &-link
      font-weight: $font-weight-bold
      text-decoration: none
      color: $brand-primary-xdark

  &__help
    margin-top: 12px
    line-height: $line-height-text
    color: $brand-gray-dark

  &__contacts
    display: flex
    flex-direction: column
    align-items: center
    flex: none
    padding-left: 32px

    a, button span:not(:hover)
      color: $brand-primary-xdark


.footer-icon-group
  display: flex
  align-items: center
  text-decoration: none

  &__icon
    flex: none

    svg
      width: 20px
      height: 20px

  &__text
    padding-left: 8px
