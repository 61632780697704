.container
  padding:
    right: $grid-gap
    left: $grid-gap

  &--sm
    padding:
      right: 16px
      left: 16px


.layout
  &__sider
    position: fixed
    top: 0
    left: 0
    z-index: 999
    display: flex
    flex-direction: column
    width: $sider-width-sm
    height: 100vh
    transition: width $transition-delay
    color: #fff
    background-color: $brand-primary-xdark

    @at-root .sider-show &
      width: $sider-width

  &__content
    display: flex
    flex-direction: column
    min-height: 100vh
    padding-left: $sider-width-sm
    transition: padding $transition-delay

    @at-root .sider-show &
      padding-left: $sider-width


.content-holder
  display: flex
  flex-direction: column
  flex: auto
  padding-bottom: 24px

  .page-heading
    + form:last-child
      display: flex
      flex-direction: column
      flex: auto


.loader-container
  display: flex
  align-items: center
  justify-content: center
  height: 386px

.loader-auto
  display: flex
  align-items: center
  flex: auto
  justify-content: center

  &--bg
    background-color: #fff

.loader-container-wrap
  min-height: 100vh
  display: flex
  align-items: center
  justify-content: center

