.favorite-input-grid
  display: grid
  grid-template-columns: 380px 280px
  grid-gap: 20px


.favorite-table-comment
  display: flex
  align-items: flex-start

  &--edit
    margin: -8px 0

  &__body
    flex: auto
    word-break: break-word

  &__controls
    display: flex
    flex: none

  &__textarea
    textarea
      display: block


.favorite-drop-down-btn
  display: flex
  align-items: center
  justify-content: center
  width: 24px
  height: 24px
  margin: -2px 0


.icon-group
  display: flex
  align-items: center

  &__icon
    flex: none

    &:not(:first-child)
      padding-left: 8px

    svg
      width: 20px
      height: 20px

    &--rotate
      svg
        transform: rotate(180deg)

  &__counter
    display: flex
    align-items: center
    height: 20px
    margin-left: 8px
    padding: 0 6px
    border: 1px solid
      radius: 10px
    font-size: $font-size-xsmall

  &__text:not(:first-child)
    padding-left: 8px

  &__badge
    color: #ffffff
    border-radius: 50%
    padding: 2px 8px
    background-color: $brand-primary-light

  &--disabled
    pointer-events: none
    cursor: default
    opacity: 0.4

