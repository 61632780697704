.drop-down
  position: relative

  &__button
    display: block

  &__body
    display: none
    //opacity: 0
    //visibility: hidden
    //transition: opacity 150ms, visibility 150ms

  &.active
    .drop-down__body
      display: block
      //opacity: 1
      //visibility: visible


.drop-down-panel
  position: absolute
  top: 100%
  right: 0
  z-index: 2
  margin-top: 12px
  padding: 8px
  width: max-content
  border: 1px solid $brand-gray-other
    radius: 8px
  box-shadow: 0 4px 12px rgba(146, 146, 146, 0.2)
  background-color: #fff

  &.top
    top: auto
    bottom: 120%

  &__list

    &-item
      display: flex
      align-items: center
      width: 100%
      padding: 8px 12px
      border-radius: 4px
      text-decoration: none
      color: black

      &[disabled]
        pointer-events: none
        cursor: default
        opacity: 0.4

      &:not(:first-child)
        margin-top: 4px

      svg
        width: 18px
        height: 18px
        margin-right: 8px

        .fill
          fill: $brand-gray-dark

      &:hover
        color: #fff
        background-color: $brand-primary

        .fill
          fill: #fff
