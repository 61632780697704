.table-data
  &__item
    padding-bottom: 16px

    .table-data__item
      padding-bottom: 0

    &:not(:first-child)
      padding-top: 16px

    &:not(:last-child)
      border-bottom: 1px solid $brand-gray-other

    &--not-border
      .table-data__item
        border-bottom: none

  &__group
    display: flex
    align-items: flex-start

  &__label
    display: flex
    align-items: center
    align-self: stretch
    flex: none
    max-height: 36px
    padding-right: 12px
    color: $brand-gray-dark

    &--main
      width: 228px

  &__control
    display: flex
    justify-content: flex-end
    flex: none
    width: 68px

  &__body
    flex: auto
    overflow-wrap: anywhere

  &__required
    flex: none
    display: inline-block
    width: 8px
    height: 8px
    vertical-align: top
    background:
      repeat: no-repeat
      position: top
      size: contain
      image: url("../../images/icons/required.svg")

    @at-root .table-data__label > &
      margin:
        top: -10px
        left: 2px


.table-data-row-control
  line-height: 36px


.table-data-grid-2
  display: grid
  grid-template-columns: repeat(2, 1fr)
  grid-gap: 16px

.table-data-grid-3
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-gap: 16px

.table-data-grid-4
  display: grid
  grid-template-columns: repeat(4, 1fr)
  grid-gap: 16px

.table-data-grid-2-1
  display: grid
  grid-template-columns: 2fr 1fr
  grid-gap: 16px

.table-data-grid-1-2
  display: grid
  grid-template-columns: 1fr 2fr
  grid-gap: 16px

.table-data-grid-3-1
  display: grid
  grid-template-columns: 3fr 1fr
  grid-gap: 16px

.table-data-grid-3-3-4
  display: grid
  grid-template-columns: 3fr 3fr 4fr
  grid-gap: 16px

.table-data-striped
  &__item
    display: flex
    padding: 10px $grid-gap
    border-top: 1px solid $brand-gray-other

    &:nth-child(even)
      background-color: #f9f9fb

  &__label
    flex: none
    width: 152px
    padding-right: 12px

    &--edit
      width: 228px

  &__body
    flex: auto

  &--border-bottom
    .table-data-striped__item:last-child
      border-bottom: 1px solid $brand-gray-other

.table-no-data
  padding: 0 24px 24px 24px
  color: $brand-gray-dark

.table-row-item-height
  height: 36px

  & + &
    margin-top: 24px

.table-row-data-height
  height: 52px

.table-archive
  color: $brand-gray-dark
  text-decoration: none
