.event-item
  display: flex
  align-items: flex-start
  padding: 12px 16px
  border-radius: 8px
  background-color: #fff

  &__media
    display: flex
    align-items: center
    justify-content: center
    flex: none
    width: 36px
    height: 36px
    margin-right: 12px
    border-radius: 8px

    &--danger
      background-color: $brand-danger-light

    &--primary
      background-color: $brand-primary-light

    &--warning
      background-color: #FFCC2E

    &--success
      background-color: $brand-success-light

  &__body
    display: flex
    justify-content: space-between
    align-items: flex-start
    flex: auto
    font-size: $font-size-small

    a
      +link-brand

.event-new-badge
  display: flex
  align-items: center
  height: 20px
  padding: 0 7px
  border: 1px solid
    radius: 10px
  font-size: $font-size-xsmall
  font-weight: $font-weight-bold
  color: $brand-warning-dark

  &::before
    content: 'нов'

.event-date
  min-width: 115px

