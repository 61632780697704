.service-search-group-grid
  display: grid
  grid-gap: 20px
  grid-template-columns: 3fr 2fr

.service-panels-list
  &__item
    &:not(:first-child)
      margin-top: 8px

.service-panels-list-gl
  &__item
    &:not(:first-child)
      margin-top: 20px

.service-child-media
  display: flex

  &__num
    display: flex
    padding-top: 2px

  &__body
    flex: auto
    padding: 0 16px

  &__status
    flex: none

.service-photo-grid
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(224px, auto))
  grid-gap: 16px

.service-photo
  cursor: pointer

  &__inner
    position: relative
    padding-top: 67%

  &__img
    position: absolute
    top: 0
    left: 0
    display: block
    width: 100%
    height: 100%
    border-radius: 8px
    background:
      position: center
      size: cover
      color: $brand-gray-other

  &__head
    position: absolute
    top: 0
    right: 0
    left: 0
    display: flex
    align-items: center
    height: 40px
    padding-left: 8px
    border-top-left-radius: 8px
    border-top-right-radius: 8px
    font:
      size: $font-size-base
      weight: $font-weight-bold
    color: #fff
    background-color: rgba(13, 13, 16, 0.64)

  &__dnd
    display: flex
    align-items: center
    align-self: stretch
    padding-right: 8px
    cursor: pointer

  &__delete
    display: flex
    align-items: center
    justify-content: center
    align-self: stretch
    width: 32px
    margin-left: auto
    padding-right: 4px
