.report__little--title
  font-size: $font-size-small
  margin-bottom: 5px
  color: $brand-gray-dark
  font-weight: normal

.report__big--title
  line-height: $font-size-large
  margin-right: 40px

.report__search--wrap
  display: grid
  grid-template-columns: 1fr auto

  .search__wrap--input
    width: 100%

  .search__wrap--btn
    margin-left: 10px


.report__age--wrap
  display: flex

  .report__age--element
    max-width: 150px

.wrap__switch
  display: flex
  flex-wrap: wrap
  column-gap: 10px

  .column__switch

    &.big__size
      min-width: 680px

.report__schedule--popup
  .schedule__popup--item
    padding: 20px 24px
    height: 77px
    border-bottom: 1px solid #E8E8EF
    display: flex
    align-items: center
    justify-content: space-between
    z-index: 10
    width: 100%

    &.odd
      background: #F9F9FB

    .select
      position: absolute
      width: 100px
      right: 25px

    .no-work
      color: $brand-gray-dark


.report__iframe
  top: 0
  width: 100%
  height: 100vh
  border: none

.report-search
  width: 280px

.report__option
  display: grid
  column-gap: 20px
  grid-template-columns: 1fr 1fr

.save__report--title
  font-size: $font-size-base
  padding: 0
  margin: 0

.save__report--result
  list-style-type: none
  padding: 0

  &-item
    line-height: $line-height-text
    font-size: $font-size-small
    color: $brand-gray-dark

    .result
      color: $text-color

