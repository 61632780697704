.header
  border-bottom: 1px solid $brand-gray-other
  background-color: #fff

  &__inner
    display: flex
    align-items: center
    height: 68px


.page-heading
  position: sticky
  top: 0
  z-index: 104 // изменен, так как datePicker и раскрывающий список закрывали header при раскрытии
  padding: 20px 0 0
  border-bottom: 2px solid $brand-gray-other
  background-color: #fff

  &__controls-top-right
    flex: none
    margin-left: auto
    margin-bottom: -20px
    padding-left: 16px

  &__selected
    font-weight: $font-weight-bold
    color: $brand-primary-xdark

  &__title
    display: flex
    justify-content: flex-start
    align-items: center
  &__subheader
    display: flex
    justify-content: space-between
    align-items: center
  &__controls
    width: 280px

  &__swither
    margin-left: 40px

  &__description
    color: $brand-gray-dark

  &__tabs--btn
    display: flex
    justify-content: space-between


.header-search
  display: flex
  flex: auto

  &__icon
    flex: none
    margin-right: 8px

    svg
      width: 20px
      height: 20px

  &__input
    +placeholder($brand-gray-dark)
    flex: auto
    border: none


.header-controls
  display: flex
  align-items: center
  flex: none
  margin-left: 20px

  &__link
    position: relative
    display: flex
    align-items: center
    justify-content: center
    width: 48px
    height: 48px
    margin-right: 20px

    &-counter
      position: absolute
      right: 2px
      bottom: 24px
      display: flex
      align-items: center
      height: 20px
      padding: 0 4px
      border: 3px solid #fff
        radius: 10px
      font:
        size: 11px
        weight: $font-weight-bold
      background-color: $brand-primary-light
      color: #fff


.header-user
  position: relative

  &__modal
    position: absolute
    z-index: 105 // изменен, так как нужно было увеличить значение header, то модалка не перекрывала header
    top: calc(100% + 8px)
    right: 0
    width: 260px
    padding: 0 8px
    border-radius: 12px
    box-shadow: 0 4px 12px rgba(146, 146, 146, 0.2)
    background-color: #fff

    &-container
      padding: 16px

    &-hr
      border-top: 1px solid $brand-gray-other

    &-title
      margin-top: -4px
      font-weight: $font-weight-bold
      color: $brand-gray-dark

    &-link
      display: flex
      line-height: $line-height-text
      text-decoration: none
      color: $text-color

      &:not(:first-child)
        margin-top: 16px

      &-icon
        flex: none
        margin-right: 8px


.header-user-media
  display: flex

  &__body
    padding-left: 12px
    line-height: 20px
    display: flex
    flex-direction: column

    &__name
      display: block
      font-size: 14px
      font-weight: $font-weight-bold

    &__organization
      display: inline-block
      font-size: 12px
      color: $brand-gray-dark


.header-user-object
  display: flex
  align-items: center
  justify-content: center
  width: 48px
  height: 48px
  border-radius: 50%
  letter-spacing: 0.1em
  text-transform: uppercase
  font-weight: $font-weight-bold
  background-color: #FFAB5A
  color: #fff

  &--sm
    width: 40px
    height: 40px




