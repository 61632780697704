=reset-ul
  margin:
    top: 0
    bottom: 0
  padding-left: 0
  list-style: none


=placeholder ($color: $gray-base)
  &.placeholder
    color: $color

  &:-moz-placeholder
    opacity: 1
    color: $color
  &::-moz-placeholder
    opacity: 1
    color: $color

  &::-webkit-input-placeholder
    color: $color

  &:-ms-input-placeholder
    color: $color


=link-brand
  font-weight: $font-weight-bold
  text-decoration: none
  color: $brand-primary
